import React from "react";
import SEO from "@/components/seo";
import Layout from "@/components/layout";
import AboutPageComponent from "@/components/AboutPageComponent";

const PAGE = {
  pageTitle: 'KOJI OTANI | Gallery',
  sectionTitle: '...',
  pageDescription: '...',
  headerColor: 'white',
}

const AboutPage = (props) => {
  return (
    <Layout page={PAGE}>
      <SEO title={PAGE.pageTitle} />
      <AboutPageComponent
        title={'About Me'}
        text={'OTANI KOJI <br /><br />07.07.2019 <br class="mobile-only" /> I left Tokyo. I just wanna travel.'}
        social={[
          {
            title: 'email',
            href: 'mailto:otani.travelphotography@gmail.com'
          },
          {
            title: 'facebook',
            href: 'https://www.facebook.com/KOJI-PHOTo-101430347982975/'
          },
          {
            title: 'instagram',
            href: 'https://www.instagram.com/otani_k'
          }
        ]}
      />
    </Layout>
  );
};

export default AboutPage;